import { axiosInstance } from '../axios'

type FetchMenuPointsResponse = {
  topics: {
    id: string
    title: string
    sortingKey: number
    color: string | null
    categories: {
      id: string
      title: string
      subCategories: { id: string; title: string }[]
    }[]
  }[]
}

export const fetchMenuPoints = () =>
  axiosInstance.get<FetchMenuPointsResponse>('api/filled-materials?pagination[pageSize]=999')
