import axios from 'axios'

const BASE_URL = process.env.REACT_APP_API_URL

export const axiosInstance = axios.create({
  baseURL: BASE_URL
})

axiosInstance.defaults.headers['Content-Type'] = 'application/json'
// eslint-disable-next-line @typescript-eslint/dot-notation
axiosInstance.defaults.headers['Authorization'] = `Bearer ${process.env.REACT_APP_ACCESS_TOKEN}`

axiosInstance.interceptors.response.use(
  (response) => {
    return response
  },
  function (error) {
    return Promise.reject(error)
  }
)
