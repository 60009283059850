import React from 'react'
import { styles } from './styles'

export const Footer = () => (
  <div style={styles.box}>
    <a style={styles.footerLink} href='/kontakt'>
      Kontakt
    </a>
    <a style={styles.footerLink} href='/impressum'>
      Impressum
    </a>
    <a style={styles.footerLink} href='/datenschutz'>
      Datenschutzinformationen
    </a>
  </div>
)
