import { createInstance } from '@jonkoops/matomo-tracker-react'

export const matomoInstance = createInstance({
  urlBase: process.env.REACT_APP_MATOMO_URL || 'xxx',
  siteId: parseInt(process.env.REACT_APP_MATOMO_SITE_ID || '-1'),
  // userId: 'UID76903202', // optional, default value: `undefined`.
  trackerUrl: `${process.env.REACT_APP_MATOMO_URL}piwik.php`, // optional, default value: `${urlBase}matomo.php`
  // srcUrl: 'https://LINK.TO.DOMAIN/tracking.js', // optional, default value: `${urlBase}matomo.js`
  disabled: false, // optional, false by default. Makes all tracking calls no-ops if set to true.
  heartBeat: {
    // optional, enabled by default
    active: true, // optional, default value: true
    seconds: 10 // optional, default value: `15
  },
  linkTracking: false // optional, default value: true
  // configurations: {
  //   // optional, default value: {}
  //   // any valid matomo configuration, all below are optional
  //   disableCookies: true,
  //   setSecureCookie: true,
  //   setRequestMethod: 'POST'
  // }
})
