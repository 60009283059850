import { FC, LazyExoticComponent, Suspense, lazy } from 'react'
import LoadingScreen from '../features/loading-screen'
import { NotFound } from '../features/NotFound'
import { JSX } from 'react/jsx-runtime'

const Loadable = (Component: any) => (props: JSX.IntrinsicAttributes) =>
  (
    <Suspense fallback={<LoadingScreen />}>
      <Component {...props} />
    </Suspense>
  )

const Overview = Loadable(lazy(() => import('../features/overview')))
const Search = Loadable(lazy(() => import('../features/search')))
const Contact = Loadable(lazy(() => import('../features/contact')))
const DataProtection = Loadable(lazy(() => import('../features/data-protection')))
const Imprint = Loadable(lazy(() => import('../features/imprint')))
const Partners = Loadable(lazy(() => import('../features/partners')))

export const defaultRoute = [
  {
    path: '/pytac',
    element: <Search />
  },
  { path: '/partnerojo', element: <Partners /> },
  { path: '/datenschutz', element: <DataProtection /> },
  { path: '/kontakt', element: <Contact /> },
  { path: '/impressum', element: <Imprint /> },
  {
    path: '/',
    children: [{ path: '', element: <Overview /> }]
  },
  {
    path: '',
    element: <NotFound />
  }
]
