import React from 'react'
import ReactDOM from 'react-dom/client'
import App from './App'
import reportWebVitals from './reportWebVitals'
import { ThemeProvider } from '@emotion/react'
import { theme } from './theme'
import { BrowserRouter } from 'react-router-dom'
import { QueryClientProvider } from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import { queryClient } from './lib/queryClient'
import { Toaster } from 'react-hot-toast'
import { CssBaseline } from '@mui/material'
import { matomoInstance } from './lib/matomo'
import { MatomoProvider } from '@jonkoops/matomo-tracker-react'
import { SearchParamsProvider } from './contexts/search-params-context'

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
root.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <MatomoProvider value={matomoInstance}>
        <QueryClientProvider client={queryClient}>
          <BrowserRouter>
            <SearchParamsProvider>
              <CssBaseline />
              <ReactQueryDevtools initialIsOpen={false} />
              <App />
              <Toaster />
            </SearchParamsProvider>
          </BrowserRouter>
        </QueryClientProvider>
      </MatomoProvider>
    </ThemeProvider>
  </React.StrictMode>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals()
