import { theme } from 'src/theme'

const box = {
  background: theme.palette.primary.main,
  opacity: 0.9,
  position: 'fixed',
  bottom: 0,
  left: 0,
  padding: '5px',
  paddingLeft: '15px',
  width: '100%',
  zIndex: 3
} as const

const footerLink = {
  color: '#fff',
  marginBottom: '10px',
  fontSize: '18px',
  textDecoration: 'none',
  paddingTop: '10px',
  paddingRight: '10px',
  '&:hover': {
    color: 'red',
    transition: '200ms ease-in'
  }
} as const

export const styles = { box, footerLink }
